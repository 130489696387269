export const ADD_HE_DETAILS='ADD_HE_DETAILS';
export const ADD_SHE_DETAILS="ADD_SHE_DETAILS";
export const ADD_REQUIRED_DETAILS='ADD_REQUIRED_DETAILS';
export const GET_DIVORCE_DETAILS='GET_DIVORCE_DETAILS';
export const ADD_CONTACT='ADD_CONTACT';
export const ADD_REACH_OUT='ADD_REACH_OUT';

export const GET_HEART='GET_HEART';
export const GET_BLOG='GET_BLOG';
export const GET_BLOG_BY_SLUG='GET_BLOG_BY_SLUG';

export const VERIFY_OTP='VERIFY_OTP';
export const RESEND_OTP='RESEND_OTP';

export const ADD_MFPFORM='ADD_MFPFORM';